.pf-c-toggle-group {
  --pf-c-toggle-group__button--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-toggle-group__button--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-toggle-group__button--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-toggle-group__button--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-toggle-group__button--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-toggle-group__button--LineHeight: calc(var(--pf-global--FontSize--md) * var(--pf-global--LineHeight--md));
  --pf-c-toggle-group__button--Color: var(--pf-global--Color--100);
  --pf-c-toggle-group__button--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-toggle-group__button--hover--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-toggle-group__button--focus--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-toggle-group__button--disabled--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-toggle-group__button--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-toggle-group__item--first-child__button--BorderTopLeftRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-toggle-group__item--first-child__button--BorderBottomLeftRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-toggle-group__item--last-child__button--BorderTopRightRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-toggle-group__item--last-child__button--BorderBottomRightRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-toggle-group__icon--text--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-toggle-group__button--m-light--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-toggle-group__button--m-selected--BackgroundColor: var(--pf-global--primary-color--100);
  --pf-c-toggle-group__button--m-selected--Color: var(--pf-global--Color--light-100);
  display: flex; }

.pf-c-toggle-group__item:first-child .pf-c-toggle-group__button {
  border-top-left-radius: var(--pf-c-toggle-group__item--first-child__button--BorderTopLeftRadius);
  border-bottom-left-radius: var(--pf-c-toggle-group__item--first-child__button--BorderBottomLeftRadius); }

.pf-c-toggle-group__item:last-child .pf-c-toggle-group__button {
  border-top-right-radius: var(--pf-c-toggle-group__item--last-child__button--BorderTopRightRadius);
  border-bottom-right-radius: var(--pf-c-toggle-group__item--last-child__button--BorderBottomRightRadius); }

.pf-c-toggle-group__button {
  display: inline-flex;
  padding: var(--pf-c-toggle-group__button--PaddingTop) var(--pf-c-toggle-group__button--PaddingRight) var(--pf-c-toggle-group__button--PaddingBottom) var(--pf-c-toggle-group__button--PaddingLeft);
  font-size: var(--pf-c-toggle-group__button--FontSize);
  line-height: var(--pf-c-toggle-group__button--LineHeight);
  color: var(--pf-c-toggle-group__button--Color);
  background-color: var(--pf-c-toggle-group__button--BackgroundColor);
  border: 0; }
  .pf-c-toggle-group__button.pf-m-light {
    --pf-c-toggle-group__button--BackgroundColor: var(--pf-c-toggle-group__button--m-light--BackgroundColor); }
  .pf-c-toggle-group__button:hover {
    --pf-c-toggle-group__button--BackgroundColor: var(--pf-c-toggle-group__button--hover--BackgroundColor);
    text-decoration: none; }
  .pf-c-toggle-group__button:focus {
    --pf-c-toggle-group__button--BackgroundColor: var(--pf-c-toggle-group__button--focus--BackgroundColor); }
  .pf-c-toggle-group__button.pf-m-selected {
    --pf-c-toggle-group__button--BackgroundColor: var(--pf-c-toggle-group__button--m-selected--BackgroundColor);
    --pf-c-toggle-group__button--Color: var(--pf-c-toggle-group__button--m-selected--Color); }
  .pf-c-toggle-group__button:disabled, .pf-c-toggle-group__button.pf-m-disabled {
    --pf-c-toggle-group__button--BackgroundColor: var(--pf-c-toggle-group__button--disabled--BackgroundColor);
    --pf-c-toggle-group__button--Color: var(--pf-c-toggle-group__button--disabled--Color);
    pointer-events: none; }

.pf-c-toggle-group__icon + .pf-c-toggle-group__text,
.pf-c-toggle-group__text + .pf-c-toggle-group__icon {
  margin-left: var(--pf-c-toggle-group__icon--text--MarginLeft); }
