.pf-c-menu {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100); }

.pf-c-menu {
  color: var(--pf-global--Color--100);
  --pf-c-menu--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-menu--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-menu--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu--MinWidth: 100%;
  --pf-c-menu--Width: auto;
  --pf-c-menu--m-flyout__menu--Top: calc(var(--pf-c-menu--PaddingTop) * -1);
  --pf-c-menu--c-divider--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-menu--c-divider--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__search--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__search--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__search--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__search--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__list-item--Color: var(--pf-global--Color--100);
  --pf-c-menu__list-item--BackgroundColor: transparent;
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__item--OutlineOffset: calc(0.125rem * -1);
  --pf-c-menu__item--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu__item--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-menu__item--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-menu__list-item--m-disabled__item--Color: var(--pf-global--disabled-color--100);
  --pf-c-menu__group-title--PaddingTop: var(--pf-c-menu__item--PaddingTop);
  --pf-c-menu__group-title--PaddingRight: var(--pf-c-menu__item--PaddingRight);
  --pf-c-menu__group-title--PaddingBottom: var(--pf-c-menu__item--PaddingBottom);
  --pf-c-menu__group-title--PaddingLeft: var(--pf-c-menu__item--PaddingLeft);
  --pf-c-menu__group-title--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-menu__group-title--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-menu__group-title--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-description--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-menu__item-description--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__list-item--m-disabled__item-toggle-icon--Color: var(--pf-global--disabled-color--200);
  --pf-c-menu__item-text--item-toggle-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--item-text--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-select-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-select-icon--Color: var(--pf-global--active-color--100);
  --pf-c-menu__item-select-icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__item-external-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-external-icon--Color: var(--pf-global--link--Color);
  --pf-c-menu__item-external-icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__item-external-icon--Opacity: 0;
  --pf-c-menu__item-action--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__item-action--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__item-action--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__item-action--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__item-action--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-action--hover--Color: var(--pf-global--Color--100);
  --pf-c-menu__item-action--m-favorited--Color: var(--pf-global--palette--gold-400);
  --pf-c-menu__item-action--m-favorited--hover--Color: var(--pf-global--palette--gold-500);
  --pf-c-menu__list-item--m-disabled__item-action--Color: var(--pf-global--disabled-color--200);
  --pf-c-menu__item-action-icon--Height: calc(var(--pf-c-menu__item--FontSize) * var(--pf-c-menu__item--LineHeight));
  --pf-c-menu__item-action--m-favorite__icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__breadcrumb--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__breadcrumb--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__breadcrumb--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--c-breadcrumb__item--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu__breadcrumb--c-breadcrumb__heading--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu__content--Height: auto;
  --pf-c-menu--m-drilldown--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown--TransitionDuration--height: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown--Transition: transform var(--pf-c-menu--m-drilldown--TransitionDuration--transform), height var(--pf-c-menu--m-drilldown--TransitionDuration--height);
  --pf-c-menu--m-drilldown--c-menu--Top: calc(var(--pf-c-menu--PaddingTop) * -1);
  --pf-c-menu--m-drilldown--c-menu--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown--c-menu--TransitionDuration--visibility: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown--c-menu--Transition: transform var(--pf-c-menu--m-drilldown--c-menu--TransitionDuration--transform), visibility var(--pf-c-menu--m-drilldown--c-menu--TransitionDuration--visibility);
  --pf-c-menu--m-drilldown__list--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown__list--Transition: transform var(--pf-c-menu--m-drilldown__list--TransitionDuration--transform);
  --pf-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex: var(--pf-global--ZIndex--2xl);
  width: var(--pf-c-menu--Width);
  padding-top: var(--pf-c-menu--PaddingTop);
  padding-bottom: var(--pf-c-menu--PaddingBottom);
  background-color: var(--pf-c-menu--BackgroundColor);
  box-shadow: var(--pf-c-menu--BoxShadow); }
  .pf-c-menu.pf-m-flyout .pf-c-menu {
    position: absolute;
    top: var(--pf-c-menu--m-flyout__menu--Top);
    left: 100%; }
  .pf-c-menu.pf-m-flyout .pf-c-menu__list-item {
    position: relative; }
  .pf-c-menu.pf-m-drilldown {
    display: flex;
    flex-direction: column;
    transition: var(--pf-c-menu--m-drilldown--Transition); }
    .pf-c-menu.pf-m-drilldown > .pf-c-menu__content {
      flex-grow: 1;
      overflow: hidden; }
    .pf-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list,
    .pf-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-c-menu__list {
      transform: translateX(-100%); }
    .pf-c-menu.pf-m-drilldown > .pf-c-menu__content .pf-c-menu {
      --pf-c-menu--BoxShadow: none;
      position: absolute;
      top: var(--pf-c-menu--m-drilldown--c-menu--Top);
      left: 100%;
      width: 100%;
      transition: var(--pf-c-menu--m-drilldown--c-menu--Transition); }
      .pf-c-menu.pf-m-drilldown > .pf-c-menu__content .pf-c-menu.pf-m-drilled-in {
        transform: translateX(-100%); }
    .pf-c-menu.pf-m-drilldown .pf-c-menu__list {
      position: relative;
      transition: var(--pf-c-menu--m-drilldown__list--Transition); }
    .pf-c-menu.pf-m-drilldown .pf-c-menu__list-item.pf-m-current-path .pf-c-menu {
      z-index: var(--pf-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex); }
    .pf-c-menu.pf-m-drilldown .pf-c-menu__list-item:not(.pf-m-current-path) .pf-c-menu {
      visibility: hidden; }
    .pf-c-menu.pf-m-drilldown .pf-c-menu__item {
      outline-offset: var(--pf-c-menu__item--OutlineOffset); }
  .pf-c-menu .pf-c-divider {
    margin-top: var(--pf-c-menu--c-divider--MarginTop);
    margin-bottom: var(--pf-c-menu--c-divider--MarginBottom); }

.pf-c-menu__breadcrumb {
  display: flex;
  align-items: center;
  min-height: var(--pf-c-menu__breadcrumb--MinHeight);
  padding: var(--pf-c-menu__breadcrumb--PaddingTop) var(--pf-c-menu__breadcrumb--PaddingRight) var(--pf-c-menu__breadcrumb--PaddingBottom) var(--pf-c-menu__breadcrumb--PaddingLeft); }
  .pf-c-menu__breadcrumb .pf-c-breadcrumb {
    --pf-c-breadcrumb__item--FontSize: var(--pf-c-menu__breadcrumb--c-breadcrumb__item--FontSize);
    --pf-c-breadcrumb__heading--FontSize: var(--pf-c-menu__breadcrumb--c-breadcrumb__heading--FontSize); }

.pf-c-menu__content {
  height: var(--pf-c-menu__content--Height); }

.pf-c-menu__search {
  padding-top: var(--pf-c-menu__search--PaddingTop);
  padding-right: var(--pf-c-menu__search--PaddingRight);
  padding-bottom: var(--pf-c-menu__search--PaddingBottom);
  padding-left: var(--pf-c-menu__search--PaddingLeft); }

.pf-c-menu__list-item {
  display: flex;
  color: var(--pf-c-menu__list-item--Color);
  background-color: var(--pf-c-menu__list-item--BackgroundColor); }
  .pf-c-menu__list-item:hover {
    --pf-c-menu__list-item--BackgroundColor: var(--pf-c-menu__list-item--hover--BackgroundColor); }
  .pf-c-menu__list-item:focus-within {
    --pf-c-menu__list-item--BackgroundColor: var(--pf-c-menu__list-item--focus-within--BackgroundColor); }
  .pf-c-menu__list-item.pf-m-disabled {
    --pf-c-menu__list-item--hover--BackgroundColor: transparent;
    --pf-c-menu__list-item--focus-within--BackgroundColor: transparent;
    --pf-c-menu__item--Color: var(--pf-c-menu__list-item--m-disabled__item--Color);
    --pf-c-menu__item-action--Color: var(--pf-c-menu__list-item--m-disabled__item-action--Color);
    --pf-c-menu__item-toggle-icon: var(--pf-c-menu__list-item--m-disabled__item-toggle-icon--Color); }
    .pf-c-menu__list-item.pf-m-disabled .pf-c-menu__item,
    .pf-c-menu__list-item.pf-m-disabled .pf-c-menu__item-action {
      pointer-events: none; }

.pf-c-menu__item {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  min-width: 0;
  padding-top: var(--pf-c-menu__item--PaddingTop);
  padding-right: var(--pf-c-menu__item--PaddingRight);
  padding-bottom: var(--pf-c-menu__item--PaddingBottom);
  padding-left: var(--pf-c-menu__item--PaddingLeft);
  font-size: var(--pf-c-menu__item--FontSize);
  font-weight: var(--pf-c-menu__item--FontWeight);
  line-height: var(--pf-c-menu__item--LineHeight);
  color: var(--pf-c-menu__item--Color);
  text-align: left;
  background-color: var(--pf-c-menu__item--BackgroundColor);
  border: none; }
  .pf-c-menu__item:hover {
    text-decoration: none; }
  .pf-c-menu__item:hover, .pf-c-menu__item:focus {
    --pf-c-menu__item-external-icon--Opacity: 1; }
  .pf-c-menu__item.pf-m-selected .pf-c-menu__item-select-icon {
    opacity: 1; }

.pf-c-menu__item-main {
  display: flex;
  align-items: center;
  width: 100%; }

.pf-c-menu__item-external-icon {
  margin-left: var(--pf-c-menu__item-external-icon--MarginLeft);
  font-size: var(--pf-c-menu__item-external-icon--FontSize);
  color: var(--pf-c-menu__item-external-icon--Color);
  opacity: var(--pf-c-menu__item-external-icon--Opacity); }

.pf-c-menu__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1; }

.pf-c-menu__group-title {
  padding-top: var(--pf-c-menu__group-title--PaddingTop);
  padding-right: var(--pf-c-menu__group-title--PaddingRight);
  padding-bottom: var(--pf-c-menu__group-title--PaddingBottom);
  padding-left: var(--pf-c-menu__group-title--PaddingLeft);
  font-size: var(--pf-c-menu__group-title--FontSize);
  font-weight: var(--pf-c-menu__group-title--FontWeight);
  color: var(--pf-c-menu__group-title--Color); }

.pf-c-menu__item-description {
  font-size: var(--pf-c-menu__item-description--FontSize);
  color: var(--pf-c-menu__item-description--Color);
  word-break: break-all; }

.pf-c-menu__item-icon {
  margin-right: var(--pf-c-menu__item-icon--MarginRight); }

.pf-c-menu__item-toggle-icon {
  padding-right: var(--pf-c-menu__item-toggle-icon--PaddingRight);
  padding-left: var(--pf-c-menu__item-toggle-icon--PaddingLeft);
  color: var(--pf-c-menu__item-toggle-icon, inherit); }

.pf-c-menu__item-text + .pf-c-menu__item-toggle-icon {
  margin-left: var(--pf-c-menu__item-text--item-toggle-icon--MarginLeft); }

.pf-c-menu__item-toggle-icon + .pf-c-menu__item-text {
  margin-left: var(--pf-c-menu__item-toggle-icon--item-text--MarginLeft); }

.pf-c-menu__item-select-icon {
  margin-left: var(--pf-c-menu__item-select-icon--MarginLeft);
  font-size: var(--pf-c-menu__item-select-icon--FontSize);
  color: var(--pf-c-menu__item-select-icon--Color);
  opacity: 0; }

.pf-c-menu__item-action {
  display: flex;
  padding-top: var(--pf-c-menu__item-action--PaddingTop);
  padding-right: var(--pf-c-menu__item-action--PaddingRight);
  padding-bottom: var(--pf-c-menu__item-action--PaddingBottom);
  padding-left: var(--pf-c-menu__item-action--PaddingLeft);
  color: var(--pf-c-menu__item-action--Color);
  border: none; }
  .pf-c-menu__item-action.pf-m-favorite .pf-c-menu__item-action-icon {
    font-size: var(--pf-c-menu__item-action--m-favorite__icon--FontSize); }
  .pf-c-menu__item-action.pf-m-favorited {
    --pf-c-menu__item-action--Color: var(--pf-c-menu__item-action--m-favorited--Color);
    --pf-c-menu__item-action--hover--Color: var(--pf-c-menu__item-action--m-favorited--hover--Color); }
  .pf-c-menu__item-action:hover, .pf-c-menu__item-action:focus {
    --pf-c-menu__item-action--Color: var(--pf-c-menu__item-action--hover--Color); }

.pf-c-menu__item-action-icon {
  display: flex;
  align-items: center;
  height: var(--pf-c-menu__item-action-icon--Height); }
