.pf-c-skeleton {
  --pf-c-skeleton--BackgroundColor: var(--pf-global--palette--black-150);
  --pf-c-skeleton--Width: auto;
  --pf-c-skeleton--Height: auto;
  --pf-c-skeleton--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-skeleton--before--PaddingBottom: 0;
  --pf-c-skeleton--before--Height: auto;
  --pf-c-skeleton--before--Content: "\00a0";
  --pf-c-skeleton--after--LinearGradientAngle: 90deg;
  --pf-c-skeleton--after--LinearGradientColorStop1: rgba(237, 237, 237, 0);
  --pf-c-skeleton--after--LinearGradientColorStop2: #ededed;
  --pf-c-skeleton--after--LinearGradientColorStop3: rgba(237, 237, 237, 0);
  --pf-c-skeleton--after--TranslateX: -100%;
  --pf-c-skeleton--after--AnimationName: pf-c-skeleton-loading;
  --pf-c-skeleton--after--AnimationDuration: 2s;
  --pf-c-skeleton--after--AnimationIterationCount: infinite;
  --pf-c-skeleton--after--AnimationTimingFunction: linear;
  --pf-c-skeleton--after--AnimationDelay: .5s;
  --pf-c-skeleton--m-circle--BorderRadius: var(--pf-global--BorderRadius--lg);
  --pf-c-skeleton--m-circle--before--PaddingBottom: 100%;
  --pf-c-skeleton--m-text-4xl--Height: calc(var(--pf-global--FontSize--4xl) * var(--pf-global--LineHeight--sm));
  --pf-c-skeleton--m-text-3xl--Height: calc(var(--pf-global--FontSize--3xl) * var(--pf-global--LineHeight--sm));
  --pf-c-skeleton--m-text-2xl--Height: calc(var(--pf-global--FontSize--2xl) * var(--pf-global--LineHeight--sm));
  --pf-c-skeleton--m-text-xl--Height: calc(var(--pf-global--FontSize--xl) * var(--pf-global--LineHeight--sm));
  --pf-c-skeleton--m-text-lg--Height: calc(var(--pf-global--FontSize--lg) * var(--pf-global--LineHeight--md));
  --pf-c-skeleton--m-text-md--Height: calc(var(--pf-global--FontSize--md) * var(--pf-global--LineHeight--md));
  --pf-c-skeleton--m-text-sm--Height: calc(var(--pf-global--FontSize--sm) * var(--pf-global--LineHeight--md));
  --pf-c-skeleton--m-width-sm--Width: 6.25rem;
  --pf-c-skeleton--m-width-md--Width: 12.5rem;
  --pf-c-skeleton--m-width-lg--Width: 18.75rem;
  --pf-c-skeleton--m-width-25--Width: 25%;
  --pf-c-skeleton--m-width-33--Width: calc(100% / 3);
  --pf-c-skeleton--m-width-50--Width: 50%;
  --pf-c-skeleton--m-width-66--Width: calc(100% / 3 * 2);
  --pf-c-skeleton--m-width-75--Width: 75%;
  --pf-c-skeleton--m-height-sm--Height: 6.25rem;
  --pf-c-skeleton--m-height-md--Height: 12.5rem;
  --pf-c-skeleton--m-height-lg--Height: 18.75rem;
  --pf-c-skeleton--m-height-25--Height: 25%;
  --pf-c-skeleton--m-height-33--Height: calc(100% / 3);
  --pf-c-skeleton--m-height-50--Height: 50%;
  --pf-c-skeleton--m-height-66--Height: calc(100% / 3 * 2);
  --pf-c-skeleton--m-height-75--Height: 75%;
  --pf-c-skeleton--m-height-100--Height: 100%;
  position: relative;
  width: var(--pf-c-skeleton--Width);
  height: var(--pf-c-skeleton--Height);
  overflow: hidden;
  background-color: var(--pf-c-skeleton--BackgroundColor);
  border-radius: var(--pf-c-skeleton--BorderRadius);
  transform: translate(0); }
  .pf-c-skeleton::before {
    display: block;
    height: var(--pf-c-skeleton--before--Height);
    padding-bottom: var(--pf-c-skeleton--before--PaddingBottom);
    content: var(--pf-c-skeleton--before--Content); }
  .pf-c-skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    background: linear-gradient(var(--pf-c-skeleton--after--LinearGradientAngle), var(--pf-c-skeleton--after--LinearGradientColorStop1), var(--pf-c-skeleton--after--LinearGradientColorStop2), var(--pf-c-skeleton--after--LinearGradientColorStop3));
    transform: translateX(var(--pf-c-skeleton--after--TranslateX));
    animation: var(--pf-c-skeleton--after--AnimationName) var(--pf-c-skeleton--after--AnimationDuration) var(--pf-c-skeleton--after--AnimationTimingFunction) var(--pf-c-skeleton--after--AnimationDelay) var(--pf-c-skeleton--after--AnimationIterationCount); }
  .pf-c-skeleton.pf-m-circle {
    --pf-c-skeleton--BorderRadius: var(--pf-c-skeleton--m-circle--BorderRadius); }
  .pf-c-skeleton.pf-m-square, .pf-c-skeleton.pf-m-circle {
    --pf-c-skeleton--before--Height: 0;
    --pf-c-skeleton--before--PaddingBottom: var(--pf-c-skeleton--m-circle--before--PaddingBottom); }
  .pf-c-skeleton.pf-m-width-sm {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-sm--Width); }
  .pf-c-skeleton.pf-m-width-md {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-md--Width); }
  .pf-c-skeleton.pf-m-width-lg {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-lg--Width); }
  .pf-c-skeleton.pf-m-width-25 {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-25--Width); }
  .pf-c-skeleton.pf-m-width-33 {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-33--Width); }
  .pf-c-skeleton.pf-m-width-50 {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-50--Width); }
  .pf-c-skeleton.pf-m-width-66 {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-66--Width); }
  .pf-c-skeleton.pf-m-width-75 {
    --pf-c-skeleton--Width: var(--pf-c-skeleton--m-width-75--Width); }
  .pf-c-skeleton.pf-m-height-sm {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-sm--Height); }
  .pf-c-skeleton.pf-m-height-md {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-md--Height); }
  .pf-c-skeleton.pf-m-height-lg {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-lg--Height); }
  .pf-c-skeleton.pf-m-height-25 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-25--Height); }
  .pf-c-skeleton.pf-m-height-33 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-33--Height); }
  .pf-c-skeleton.pf-m-height-50 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-50--Height); }
  .pf-c-skeleton.pf-m-height-66 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-66--Height); }
  .pf-c-skeleton.pf-m-height-75 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-75--Height); }
  .pf-c-skeleton.pf-m-height-100 {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-height-100--Height); }
  .pf-c-skeleton.pf-m-text-4xl {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-4xl--Height); }
  .pf-c-skeleton.pf-m-text-3xl {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-3xl--Height); }
  .pf-c-skeleton.pf-m-text-2xl {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-2xl--Height); }
  .pf-c-skeleton.pf-m-text-xl {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-xl--Height); }
  .pf-c-skeleton.pf-m-text-lg {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-lg--Height); }
  .pf-c-skeleton.pf-m-text-md {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-md--Height); }
  .pf-c-skeleton.pf-m-text-sm {
    --pf-c-skeleton--Height: var(--pf-c-skeleton--m-text-sm--Height); }

@keyframes pf-c-skeleton-loading {
  0% {
    transform: translateX(-100%); }
  60% {
    transform: translateX(100%); }
  100% {
    transform: translateX(100%); } }
