.pf-c-tile {
  --pf-c-tile--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-tile--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-tile--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-tile--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-tile--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-tile--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-tile--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-tile--before--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-tile--hover--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-tile--m-selected--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-tile--m-selected--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-tile--focus--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-tile--focus--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-tile--m-disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-tile__title--Color: var(--pf-global--Color--100);
  --pf-c-tile--hover__title--Color: var(--pf-global--primary-color--100);
  --pf-c-tile--m-selected__title--Color: var(--pf-global--primary-color--100);
  --pf-c-tile--focus__title--Color: var(--pf-global--primary-color--100);
  --pf-c-tile--m-disabled__title--Color: var(--pf-global--disabled-color--100);
  --pf-c-tile__icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-tile__icon--FontSize: var(--pf-global--icon--FontSize--md);
  --pf-c-tile__icon--Color: var(--pf-global--Color--100);
  --pf-c-tile--hover__icon--Color: var(--pf-global--primary-color--100);
  --pf-c-tile--m-selected__icon--Color: var(--pf-global--primary-color--100);
  --pf-c-tile--m-disabled__icon--Color: var(--pf-global--disabled-color--100);
  --pf-c-tile--focus__icon--Color: var(--pf-global--primary-color--100);
  --pf-c-tile__header--m-stacked__icon--MarginBottom: var(--pf-global--spacer--xs);
  --pf-c-tile__header--m-stacked__icon--FontSize: var(--pf-global--icon--FontSize--lg);
  --pf-c-tile--m-display-lg__header--m-stacked__icon--FontSize: var(--pf-global--icon--FontSize--xl);
  --pf-c-tile__body--Color: var(--pf-global--Color--100);
  --pf-c-tile__body--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-tile--m-disabled__body--Color: var(--pf-global--disabled-color--100);
  position: relative;
  display: inline-grid;
  padding: var(--pf-c-tile--PaddingTop) var(--pf-c-tile--PaddingRight) var(--pf-c-tile--PaddingBottom) var(--pf-c-tile--PaddingLeft);
  text-align: center;
  cursor: pointer;
  background-color: var(--pf-c-tile--BackgroundColor);
  grid-template-rows: min-content; }
  .pf-c-tile::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: "";
    border: var(--pf-c-tile--before--BorderWidth) solid var(--pf-c-tile--before--BorderColor);
    border-radius: var(--pf-c-tile--before--BorderRadius); }
  .pf-c-tile:hover {
    --pf-c-tile__title--Color: var(--pf-c-tile--hover__title--Color);
    --pf-c-tile__icon--Color: var(--pf-c-tile--hover__icon--Color);
    --pf-c-tile--before--BorderColor: var(--pf-c-tile--hover--before--BorderColor); }
  .pf-c-tile.pf-m-selected {
    --pf-c-tile__title--Color: var(--pf-c-tile--m-selected__title--Color);
    --pf-c-tile__icon--Color: var(--pf-c-tile--m-selected__icon--Color);
    --pf-c-tile--before--BorderWidth: var(--pf-c-tile--m-selected--before--BorderWidth);
    --pf-c-tile--before--BorderColor: var(--pf-c-tile--m-selected--before--BorderColor); }
  .pf-c-tile:focus {
    --pf-c-tile__title--Color: var(--pf-c-tile--focus__title--Color);
    --pf-c-tile__icon--Color: var(--pf-c-tile--focus__icon--Color);
    --pf-c-tile--before--BorderWidth: var(--pf-c-tile--focus--before--BorderWidth);
    --pf-c-tile--before--BorderColor: var(--pf-c-tile--focus--before--BorderColor); }
  .pf-c-tile.pf-m-disabled {
    --pf-c-tile--BackgroundColor: var(--pf-c-tile--m-disabled--BackgroundColor);
    --pf-c-tile__title--Color: var(--pf-c-tile--m-disabled__title--Color);
    --pf-c-tile__body--Color: var(--pf-c-tile--m-disabled__body--Color);
    --pf-c-tile--before--BorderWidth: 0;
    --pf-c-tile__icon--Color: var(--pf-c-tile--m-disabled__icon--Color);
    pointer-events: none; }
  .pf-c-tile.pf-m-display-lg .pf-c-tile__header.pf-m-stacked {
    --pf-c-tile__icon--FontSize: var(--pf-c-tile--m-display-lg__header--m-stacked__icon--FontSize); }

.pf-c-tile__header {
  display: flex;
  align-items: center;
  justify-content: center; }
  .pf-c-tile__header.pf-m-stacked {
    --pf-c-tile__icon--MarginRight: 0;
    --pf-c-tile__icon--FontSize: var(--pf-c-tile__header--m-stacked__icon--FontSize);
    flex-direction: column;
    justify-content: initial; }
    .pf-c-tile__header.pf-m-stacked .pf-c-tile__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--pf-c-tile__header--m-stacked__icon--MarginBottom); }

.pf-c-tile__title {
  color: var(--pf-c-tile__title--Color); }

.pf-c-tile__body {
  font-size: var(--pf-c-tile__body--FontSize);
  color: var(--pf-c-tile__body--Color); }

.pf-c-tile__icon {
  margin-right: var(--pf-c-tile__icon--MarginRight);
  font-size: var(--pf-c-tile__icon--FontSize);
  color: var(--pf-c-tile__icon--Color); }
