.pf-c-data-list {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100); }

.pf-c-data-list__item-action {
  --pf-hidden-visible--visible--Visibility: visible;
  --pf-hidden-visible--hidden--Display: none;
  --pf-hidden-visible--hidden--Visibility: hidden;
  --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
  --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  display: var(--pf-hidden-visible--Display);
  visibility: var(--pf-hidden-visible--Visibility); }
  .pf-m-hidden.pf-c-data-list__item-action {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
  @media screen and (min-width: 576px) {
    .pf-m-hidden-on-sm.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-sm.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 768px) {
    .pf-m-hidden-on-md.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-md.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 992px) {
    .pf-m-hidden-on-lg.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-lg.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1200px) {
    .pf-m-hidden-on-xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }
  @media screen and (min-width: 1450px) {
    .pf-m-hidden-on-2xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility); }
    .pf-m-visible-on-2xl.pf-c-data-list__item-action {
      --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
      --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility); } }

@media screen and (min-width: 768px) {
  .pf-c-data-list:not([class*="pf-m-grid"]) {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 768px) and (min-width: 1200px) {
    .pf-c-data-list:not([class*="pf-m-grid"]) {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 768px) {
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list:not([class*="pf-m-grid"]) .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 0) {
  .pf-c-data-list.pf-m-grid-none {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 0) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-none {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 0) {
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-none .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 576px) {
  .pf-c-data-list.pf-m-grid-sm {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 576px) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-sm {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 576px) {
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-sm .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 768px) {
  .pf-c-data-list.pf-m-grid-md {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 768px) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-md {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 768px) {
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-md .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 992px) {
  .pf-c-data-list.pf-m-grid-lg {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 992px) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-lg {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 992px) {
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-lg .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 1200px) {
  .pf-c-data-list.pf-m-grid-xl {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 1200px) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-xl {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-xl .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

@media screen and (min-width: 1450px) {
  .pf-c-data-list.pf-m-grid-2xl {
    --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list__cell--cell--md--PaddingTop);
    --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list__cell--md--PaddingBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list__item-control--md--MarginRight);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list__item-action--md--MarginLeft);
    --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--md--PaddingTop); } }
  @media screen and (min-width: 1450px) and (min-width: 1200px) {
    .pf-c-data-list.pf-m-grid-2xl {
      --pf-c-data-list__item-row--PaddingRight: var(--pf-c-data-list__item-row--xl--PaddingRight);
      --pf-c-data-list__item-row--PaddingLeft: var(--pf-c-data-list__item-row--xl--PaddingLeft);
      --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-c-data-list__expandable-content-body--xl--PaddingRight);
      --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-c-data-list__expandable-content-body--xl--PaddingLeft); } }

@media screen and (min-width: 1450px) {
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__item-content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-bottom: var(--pf-c-data-list__item-content--md--PaddingBottom); }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
      margin-right: var(--pf-c-data-list__cell--MarginRight); }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell + .pf-c-data-list__cell {
      flex: 1;
      order: initial; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-align-right {
      margin-left: auto; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-no-fill {
      flex: none; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-flex-2 {
      flex-grow: 2; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-flex-3 {
      flex-grow: 3; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-flex-4 {
      flex-grow: 4; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__cell.pf-m-flex-5 {
      flex-grow: 5; }
    .pf-c-data-list.pf-m-grid-2xl .pf-c-data-list__expandable-content {
      max-height: initial;
      overflow-y: visible; } }

.pf-c-data-list {
  --pf-c-data-list--FontSize: 1em;
  --pf-c-data-list--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-data-list--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-data-list--BorderTopWidth: var(--pf-global--spacer--sm);
  --pf-c-data-list--sm--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list--sm--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list__item--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-data-list__item--m-selected--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-data-list__item--m-expanded--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-data-list__item--m-selected--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-data-list__item--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
  --pf-c-data-list__item--m-selectable--hover--ZIndex: calc(var(--pf-c-data-list__item--m-selected--ZIndex) + 1);
  --pf-c-data-list__item--m-selectable--hover--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--focus--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-selectable--active--BoxShadow: var(--pf-global--BoxShadow--sm-top), var(--pf-global--BoxShadow--sm-bottom);
  --pf-c-data-list__item--m-expanded--m-selectable--before--BackgroundColor: var(--pf-global--active-color--300);
  --pf-c-data-list__item--BorderBottomColor: var(--pf-global--BorderColor--300);
  --pf-c-data-list__item--BorderBottomWidth: 0.5rem;
  --pf-c-data-list__item--m-selectable--hover--item--BorderTopColor: var(--pf-c-data-list__item--BorderBottomColor);
  --pf-c-data-list__item--m-selectable--hover--item--BorderTopWidth: var(--pf-c-data-list__item--BorderBottomWidth);
  --pf-c-data-list__item--sm--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list__item--sm--BorderBottomColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list__item--before--BackgroundColor: transparent;
  --pf-c-data-list__item--before--Width: var(--pf-global--BorderWidth--lg);
  --pf-c-data-list__item--before--Transition: var(--pf-global--Transition);
  --pf-c-data-list__item--before--Top: 0;
  --pf-c-data-list__item--before--sm--Top: calc(var(--pf-c-data-list__item--BorderBottomWidth) * -1);
  --pf-c-data-list__item-row--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-row--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__item-row--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-row--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-content--md--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--MarginRight: var(--pf-global--spacer--xl);
  --pf-c-data-list__cell--md--PaddingBottom: 0;
  --pf-c-data-list__cell--m-icon--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__cell--cell--PaddingTop: 0;
  --pf-c-data-list__cell--cell--md--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list--cell--MinWidth: initial;
  --pf-c-data-list--cell--Overflow: visible;
  --pf-c-data-list--cell--TextOverflow: clip;
  --pf-c-data-list--cell--WhiteSpace: normal;
  --pf-c-data-list--cell--WordBreak: normal;
  --pf-c-data-list--cell--m-truncate--MinWidth: 5ch;
  --pf-c-data-list__toggle--MarginLeft: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-data-list__toggle--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__toggle--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__toggle-icon--Height: calc(var(--pf-c-data-list--FontSize) * var(--pf-c-data-list--LineHeight));
  --pf-c-data-list__toggle-icon--Transition: .2s ease-in 0s;
  --pf-c-data-list__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-data-list__item-draggable-button--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__item-draggable-button--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-draggable-button--MarginTop: calc(var(--pf-global--spacer--lg) * -1);
  --pf-c-data-list__item-draggable-button--MarginBottom: calc(var(--pf-global--spacer--lg) * -1);
  --pf-c-data-list__item-draggable-button--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-draggable-button--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-draggable-button--MarginLeft: calc(var(--pf-global--spacer--md) * -1);
  --pf-c-data-list__item-draggable-button-icon--Color: var(--pf-global--icon--Color--light);
  --pf-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color: var(--pf-global--disabled-color--200);
  --pf-c-data-list__item-draggable-button--hover__draggable-icon--Color: var(--pf-global--icon--Color--dark);
  --pf-c-data-list__item-draggable-button--focus__draggable-icon--Color: var(--pf-global--icon--Color--dark);
  --pf-c-data-list__item--m-ghost-row--after--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-data-list__item--m-ghost-row--after--Opacity: .6;
  --pf-c-data-list__item-control--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-control--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-control--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-control--md--MarginRight: var(--pf-global--spacer--xl);
  --pf-c-data-list__item-control--not-last-child--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__check--Height: calc(var(--pf-c-data-list--FontSize) * var(--pf-c-data-list--LineHeight));
  --pf-c-data-list__check--MarginTop: -0.0625rem;
  --pf-c-data-list__item-action--Display: flex;
  --pf-c-data-list__item-action--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-action--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__item-action--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__item-action--md--MarginLeft: var(--pf-global--spacer--xl);
  --pf-c-data-list__item-action--not-last-child--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list__item-action__action--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__action--MarginTop: var(--pf-c-data-list__item-action__action--MarginTop);
  --pf-c-data-list__item-action__action--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-data-list__expandable-content--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-data-list__expandable-content--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-data-list__expandable-content--MarginRight: calc(var(--pf-c-data-list__expandable-content-body--PaddingRight) * -1);
  --pf-c-data-list__expandable-content--MarginLeft: calc(var(--pf-c-data-list__expandable-content-body--PaddingLeft) * -1);
  --pf-c-data-list__expandable-content--MaxHeight: 37.5rem;
  --pf-c-data-list__expandable-content--before--Top: calc(var(--pf-c-data-list__item--BorderBottomWidth) * -1);
  --pf-c-data-list__expandable-content-body--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-data-list__expandable-content-body--md--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--xl--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--md--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-data-list__expandable-content-body--xl--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-data-list--m-compact--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-data-list--m-compact--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-data-list--m-compact__check--FontSize: var(--pf-global--FontSize--md);
  --pf-c-data-list--m-compact__cell--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--md--PaddingBottom: 0;
  --pf-c-data-list--m-compact__cell-cell--PaddingTop: 0;
  --pf-c-data-list--m-compact__cell-cell--md--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--cell--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-control--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-control--PaddingBottom: 0;
  --pf-c-data-list--m-compact__item-control--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-control--MarginRight: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-action--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-action--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-action--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-data-list--m-compact__item-action__action--MarginTop: calc(var(--pf-c-data-list--m-compact__item-action--PaddingTop) * -1);
  --pf-c-data-list--m-compact__item-action__action--MarginBottom: calc(var(--pf-c-data-list--m-compact__item-action--PaddingBottom) * -1);
  --pf-c-data-list--m-compact__action--MarginTop: var(--pf-c-data-list--m-compact__item-action__action--MarginTop);
  --pf-c-data-list--m-compact__item-content--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-draggable-button--MarginTop: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-data-list--m-compact__item-draggable-button--MarginBottom: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-data-list--m-compact__item-draggable-button--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__item-draggable-button--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-data-list--m-compact__cell--m-icon--cell--PaddingTop: var(--pf-global--spacer--sm);
  color: var(--pf-global--Color--100);
  font-size: var(--pf-c-data-list--FontSize);
  line-height: var(--pf-c-data-list--LineHeight);
  overflow-wrap: break-word;
  list-style-type: disc;
  border-top: var(--pf-c-data-list--BorderTopWidth) solid var(--pf-c-data-list--BorderTopColor); }
  @media screen and (min-width: 576px) {
    .pf-c-data-list {
      --pf-c-data-list--BorderTopColor: var(--pf-c-data-list--sm--BorderTopColor);
      --pf-c-data-list--BorderTopWidth: var(--pf-c-data-list--sm--BorderTopWidth); } }
  @media screen and (min-width: 576px) {
    .pf-c-data-list {
      --pf-c-data-list__item--BorderBottomWidth: var(--pf-c-data-list__item--sm--BorderBottomWidth);
      --pf-c-data-list__item--BorderBottomColor: var(--pf-c-data-list__item--sm--BorderBottomColor); } }
  @media (min-width: 576px) {
    .pf-c-data-list {
      --pf-c-data-list__item--before--Top: var(--pf-c-data-list__item--before--sm--Top); } }
  .pf-c-data-list.pf-m-compact {
    --pf-c-data-list__check--FontSize: var(--pf-c-data-list--m-compact__check--FontSize);
    --pf-c-data-list__action--MarginTop: var(--pf-c-data-list--m-compact__action--MarginTop);
    --pf-c-data-list--FontSize: var(--pf-c-data-list--m-compact--FontSize);
    --pf-c-data-list__item-action--MarginLeft: var(--pf-c-data-list--m-compact__item-action--MarginLeft);
    --pf-c-data-list__item-action--PaddingTop: var(--pf-c-data-list--m-compact__item-action--PaddingTop);
    --pf-c-data-list__item-action--PaddingBottom: var(--pf-c-data-list--m-compact__item-action--PaddingBottom);
    --pf-c-data-list__item-action__action--MarginTop: var(--pf-c-data-list--m-compact__item-action__action--MarginTop);
    --pf-c-data-list__item-action__action--MarginBottom: var(--pf-c-data-list--m-compact__item-action__action--MarginBottom);
    --pf-c-data-list__item-control--MarginRight: var(--pf-c-data-list--m-compact__item-control--MarginRight);
    --pf-c-data-list__item-control--PaddingTop: var(--pf-c-data-list--m-compact__item-control--PaddingTop);
    --pf-c-data-list__item-control--PaddingBottom: var(--pf-c-data-list--m-compact__item-control--PaddingBottom);
    --pf-c-data-list__item-content--md--PaddingBottom: var(--pf-c-data-list--m-compact__item-content--PaddingBottom);
    --pf-c-data-list__item-draggable-button--MarginTop: var(--pf-c-data-list--m-compact__item-draggable-button--MarginTop);
    --pf-c-data-list__item-draggable-button--MarginBottom: var(--pf-c-data-list--m-compact__item-draggable-button--MarginBottom);
    --pf-c-data-list__item-draggable-button--PaddingTop: var(--pf-c-data-list--m-compact__item-draggable-button--PaddingTop);
    --pf-c-data-list__item-draggable-button--PaddingBottom: var(--pf-c-data-list--m-compact__item-draggable-button--PaddingBottom);
    --pf-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-c-data-list--m-compact__cell--m-icon--cell--PaddingTop);
    font-size: var(--pf-c-data-list--m-compact--FontSize); }
    .pf-c-data-list.pf-m-compact .pf-c-data-list__cell {
      --pf-c-data-list__cell--PaddingTop: var(--pf-c-data-list--m-compact__cell--PaddingTop);
      --pf-c-data-list__cell--PaddingBottom: var(--pf-c-data-list--m-compact__cell--PaddingBottom);
      --pf-c-data-list__cell--MarginRight: var(--pf-c-data-list--m-compact__cell--cell--MarginRight);
      --pf-c-data-list__cell--cell--PaddingTop: var(--pf-c-data-list--m-compact__cell-cell--PaddingTop); }
    .pf-c-data-list.pf-m-compact .pf-c-data-list__check {
      font-size: var(--pf-c-data-list--m-compact__check--FontSize); }
  .pf-c-data-list.pf-m-drag-over {
    overflow-anchor: none; }

.pf-c-data-list.pf-m-truncate,
.pf-c-data-list__item-row.pf-m-truncate,
.pf-c-data-list__cell.pf-m-truncate,
.pf-c-data-list__text.pf-m-truncate {
  --pf-c-data-list--cell--MinWidth: var(--pf-c-data-list--cell--m-truncate--MinWidth);
  --pf-c-data-list--cell--Overflow: hidden;
  --pf-c-data-list--cell--TextOverflow: ellipsis;
  --pf-c-data-list--cell--WhiteSpace: nowrap; }

.pf-c-data-list.pf-m-break-word,
.pf-c-data-list__item-row.pf-m-break-word,
.pf-c-data-list__cell.pf-m-break-word,
.pf-c-data-list__text.pf-m-break-word {
  --pf-c-data-list--cell--WordBreak: break-word; }

.pf-c-data-list.pf-m-nowrap,
.pf-c-data-list__item-row.pf-m-nowrap,
.pf-c-data-list__cell.pf-m-nowrap,
.pf-c-data-list__text.pf-m-nowrap {
  --pf-c-data-list--cell--WhiteSpace: nowrap; }

.pf-c-data-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pf-c-data-list__item--BackgroundColor);
  border-bottom: var(--pf-c-data-list__item--BorderBottomWidth) solid var(--pf-c-data-list__item--BorderBottomColor); }
  .pf-c-data-list__item::before {
    position: absolute;
    top: var(--pf-c-data-list__item--before--Top);
    bottom: 0;
    left: 0;
    width: var(--pf-c-data-list__item--before--Width);
    content: "";
    background-color: var(--pf-c-data-list__item--before--BackgroundColor);
    transition: var(--pf-c-data-list__item--before--Transition); }
  .pf-c-data-list__item.pf-m-selectable {
    cursor: pointer;
    outline-offset: var(--pf-c-data-list__item--m-selectable--OutlineOffset); }
    .pf-c-data-list__item.pf-m-selectable:hover, .pf-c-data-list__item.pf-m-selectable:focus {
      position: relative;
      z-index: var(--pf-c-data-list__item--m-selectable--hover--ZIndex); }
      .pf-c-data-list__item.pf-m-selectable:hover:not(.pf-m-selected):not(:last-child), .pf-c-data-list__item.pf-m-selectable:focus:not(.pf-m-selected):not(:last-child) {
        --pf-c-data-list__item--BorderBottomWidth: 0; }
        .pf-c-data-list__item.pf-m-selectable:hover:not(.pf-m-selected):not(:last-child) + .pf-c-data-list__item, .pf-c-data-list__item.pf-m-selectable:focus:not(.pf-m-selected):not(:last-child) + .pf-c-data-list__item {
          border-top: var(--pf-c-data-list__item--m-selectable--hover--item--BorderTopWidth) solid var(--pf-c-data-list__item--m-selectable--hover--item--BorderTopColor); }
    .pf-c-data-list__item.pf-m-selectable:hover {
      box-shadow: var(--pf-c-data-list__item--m-selectable--hover--BoxShadow); }
    .pf-c-data-list__item.pf-m-selectable:focus {
      box-shadow: var(--pf-c-data-list__item--m-selectable--focus--BoxShadow); }
    .pf-c-data-list__item.pf-m-selectable:active {
      box-shadow: var(--pf-c-data-list__item--m-selectable--active--BoxShadow); }
  .pf-c-data-list__item.pf-m-selected {
    --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-selected--before--BackgroundColor);
    position: relative;
    z-index: var(--pf-c-data-list__item--m-selected--ZIndex);
    box-shadow: var(--pf-c-data-list__item--m-selected--BoxShadow); }
  .pf-c-data-list__item.pf-m-ghost-row::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: var(--pf-c-data-list__item--m-ghost-row--after--BackgroundColor);
    opacity: var(--pf-c-data-list__item--m-ghost-row--after--Opacity); }
  .pf-c-data-list__item.pf-m-expanded {
    --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-expanded--before--BackgroundColor); }
    .pf-c-data-list__item.pf-m-expanded.pf-m-selectable:not(.pf-m-selected) {
      --pf-c-data-list__item--before--BackgroundColor: var(--pf-c-data-list__item--m-expanded--m-selectable--before--BackgroundColor); }

.pf-c-data-list__item-row {
  display: flex;
  flex-wrap: nowrap;
  padding-right: var(--pf-c-data-list__item-row--PaddingRight);
  padding-left: var(--pf-c-data-list__item-row--PaddingLeft); }

.pf-c-data-list__item-control {
  display: flex;
  flex-wrap: nowrap;
  padding-top: var(--pf-c-data-list__item-control--PaddingTop);
  padding-bottom: var(--pf-c-data-list__item-control--PaddingBottom);
  margin-right: var(--pf-c-data-list__item-control--MarginRight); }
  .pf-c-data-list__item-control > *:not(:last-child) {
    margin-right: var(--pf-c-data-list__item-control--not-last-child--MarginRight); }

.pf-c-data-list__check {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: var(--pf-c-data-list__check--Height);
  margin-top: var(--pf-c-data-list__check--MarginTop); }

.pf-c-data-list__item-draggable-button {
  display: flex;
  flex-direction: column;
  padding: var(--pf-c-data-list__item-draggable-button--PaddingTop) var(--pf-c-data-list__item-draggable-button--PaddingRight) var(--pf-c-data-list__item-draggable-button--PaddingBottom) var(--pf-c-data-list__item-draggable-button--PaddingLeft);
  margin: var(--pf-c-data-list__item-draggable-button--MarginTop) 0 var(--pf-c-data-list__item-draggable-button--MarginBottom) var(--pf-c-data-list__item-draggable-button--MarginLeft);
  border: 0; }
  .pf-c-data-list__item-draggable-button:hover {
    --pf-c-data-list__item-draggable-button-icon--Color: var(--pf-c-data-list__item-draggable-button--hover__draggable-icon--Color);
    cursor: grab; }
  .pf-c-data-list__item-draggable-button:focus {
    --pf-c-data-list__item-draggable-button-icon--Color: var(--pf-c-data-list__item-draggable-button--focus__draggable-icon--Color); }
  .pf-c-data-list__item-draggable-button:active {
    cursor: grabbing; }
  .pf-c-data-list__item-draggable-button.pf-m-disabled {
    --pf-c-data-list__item-draggable-button-icon--Color: var(--pf-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color);
    pointer-events: none;
    cursor: none; }
  .pf-c-data-list__item-draggable-button .pf-c-data-list__item-draggable-icon {
    color: var(--pf-c-data-list__item-draggable-button-icon--Color); }

.pf-c-data-list__item-action {
  --pf-hidden-visible--visible--Display: var(--pf-c-data-list__item-action--Display);
  align-items: flex-start;
  align-content: flex-start;
  padding-top: var(--pf-c-data-list__item-action--PaddingTop);
  padding-bottom: var(--pf-c-data-list__item-action--PaddingBottom);
  margin-left: var(--pf-c-data-list__item-action--MarginLeft); }
  .pf-c-data-list__item-action > *:not(:last-child) {
    margin-right: var(--pf-c-data-list__item-action--not-last-child--MarginRight); }
  .pf-c-data-list__item-action .pf-c-data-list__action {
    margin-top: var(--pf-c-data-list__action--MarginTop);
    margin-bottom: var(--pf-c-data-list__item-action__action--MarginBottom); }

.pf-c-data-list__toggle {
  margin: var(--pf-c-data-list__toggle--MarginTop) 0 var(--pf-c-data-list__toggle--MarginBottom) var(--pf-c-data-list__toggle--MarginLeft); }

.pf-c-data-list__toggle-icon {
  height: var(--pf-c-data-list__toggle-icon--Height);
  pointer-events: none;
  transition: var(--pf-c-data-list__toggle-icon--Transition); }
  .pf-c-data-list__item.pf-m-expanded .pf-c-data-list__toggle-icon {
    transform: rotate(var(--pf-c-data-list__item--m-expanded__toggle-icon--Rotate)); }

.pf-c-data-list__item-content {
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr; }

.pf-c-data-list__cell {
  flex: 1;
  grid-column: 1 / -1;
  padding-top: var(--pf-c-data-list__cell--PaddingTop);
  padding-bottom: var(--pf-c-data-list__cell--PaddingBottom); }
  .pf-c-data-list__cell + .pf-c-data-list__cell {
    flex: 1 0 100%;
    order: 1;
    padding-top: var(--pf-c-data-list__cell--cell--PaddingTop); }
  .pf-c-data-list__cell.pf-m-icon {
    flex-grow: 0;
    margin-right: var(--pf-c-data-list__cell--m-icon--MarginRight);
    grid-column: 1 / 2; }
  .pf-c-data-list__cell.pf-m-icon + .pf-c-data-list__cell {
    grid-column: 2 / 3;
    padding-top: var(--pf-c-data-list__cell--m-icon--cell--PaddingTop); }
  .pf-c-data-list__cell.pf-m-align-right {
    margin-left: 0; }

.pf-c-data-list__text {
  display: inline-block; }

.pf-c-data-list__text,
.pf-c-data-list__cell {
  min-width: var(--pf-c-data-list--cell--MinWidth);
  max-width: 100%;
  overflow: var(--pf-c-data-list--cell--Overflow);
  text-overflow: var(--pf-c-data-list--cell--TextOverflow);
  word-break: var(--pf-c-data-list--cell--WordBreak);
  white-space: var(--pf-c-data-list--cell--WhiteSpace); }

.pf-c-data-list__expandable-content {
  max-height: var(--pf-c-data-list__expandable-content--MaxHeight);
  overflow-y: auto;
  border-top: var(--pf-c-data-list__expandable-content--BorderTopWidth) solid var(--pf-c-data-list__expandable-content--BorderTopColor); }
  .pf-c-data-list__expandable-content .pf-c-data-list__expandable-content-body {
    padding: var(--pf-c-data-list__expandable-content-body--PaddingTop) var(--pf-c-data-list__expandable-content-body--PaddingRight) var(--pf-c-data-list__expandable-content-body--PaddingBottom) var(--pf-c-data-list__expandable-content-body--PaddingLeft); }
    .pf-c-data-list__expandable-content .pf-c-data-list__expandable-content-body.pf-m-no-padding {
      padding: 0; }
