.pf-c-notification-badge {
  --pf-c-notification-badge--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-notification-badge--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-notification-badge--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-notification-badge--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-notification-badge--MarginTop: calc(-1 * var(--pf-global--spacer--form-element));
  --pf-c-notification-badge--MarginRight: calc(-1 * var(--pf-global--spacer--md));
  --pf-c-notification-badge--MarginBottom: calc(-1 * var(--pf-global--spacer--form-element));
  --pf-c-notification-badge--MarginLeft: calc(-1 * var(--pf-global--spacer--md));
  --pf-c-notification-badge--after--BorderColor: transparent;
  --pf-c-notification-badge--after--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-notification-badge--after--BorderWidth: 0;
  --pf-c-notification-badge--after--Top: 0;
  --pf-c-notification-badge--after--Right: 0;
  --pf-c-notification-badge--after--Width: auto;
  --pf-c-notification-badge--after--Height: auto;
  --pf-c-notification-badge--after--BackgroundColor: transparent;
  --pf-c-notification-badge--after--TranslateX: 0;
  --pf-c-notification-badge--after--TranslateY: 0;
  --pf-c-notification-badge__i--Width: auto;
  --pf-c-notification-badge__i--Height: auto;
  --pf-c-notification-badge--m-read--after--BorderColor: transparent;
  --pf-c-notification-badge--m-read--after--BackgroundColor: transparent;
  --pf-c-notification-badge--m-unread--Color: var(--pf-global--Color--light-100);
  --pf-c-notification-badge--m-unread--after--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-notification-badge--m-unread--hover--after--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-notification-badge--m-attention--Color: var(--pf-global--Color--light-100);
  --pf-c-notification-badge--m-attention--after--BackgroundColor: var(--pf-global--danger-color--100);
  --pf-c-notification-badge--m-attention--hover--after--BackgroundColor: var(--pf-global--danger-color--200);
  --pf-c-notification-badge__count--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-notification-badge--pf-icon-attention-bell--LineHeight: var(--pf-global--LineHeight--sm);
  position: relative;
  display: inline-block;
  padding: var(--pf-c-notification-badge--PaddingTop) var(--pf-c-notification-badge--PaddingRight) var(--pf-c-notification-badge--PaddingBottom) var(--pf-c-notification-badge--PaddingLeft);
  margin: var(--pf-c-notification-badge--MarginTop) var(--pf-c-notification-badge--MarginRight) var(--pf-c-notification-badge--MarginBottom) var(--pf-c-notification-badge--MarginLeft);
  background-color: var(--pf-c-notification-badge--after--BackgroundColor);
  border-radius: var(--pf-c-notification-badge--after--BorderRadius); }
  .pf-c-notification-badge::before {
    position: absolute;
    top: var(--pf-c-notification-badge--after--Top);
    right: var(--pf-c-notification-badge--after--Right);
    bottom: 0;
    left: 0;
    width: var(--pf-c-notification-badge--after--Width);
    height: var(--pf-c-notification-badge--after--Height);
    content: "";
    border: var(--pf-c-notification-badge--after--BorderWidth) solid var(--pf-c-notification-badge--after--BorderColor);
    border-radius: var(--pf-c-notification-badge--after--BorderRadius);
    transform: translate(var(--pf-c-notification-badge--after--TranslateX), var(--pf-c-notification-badge--after--TranslateY)); }
  .pf-c-notification-badge > i {
    width: var(--pf-c-notification-badge__i--Width);
    height: var(--pf-c-notification-badge__i--Height); }
  .pf-c-notification-badge > * {
    position: relative; }
  .pf-c-notification-badge .pf-icon-attention-bell,
  .pf-c-notification-badge .pf-icon-bell {
    display: inline-block;
    line-height: var(--pf-c-notification-badge--pf-icon-attention-bell--LineHeight); }
    .pf-c-notification-badge .pf-icon-attention-bell::before,
    .pf-c-notification-badge .pf-icon-bell::before {
      vertical-align: bottom; }
  .pf-c-notification-badge.pf-m-read {
    --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-notification-badge--m-read--after--BackgroundColor);
    --pf-c-notification-badge--after--BorderColor: var(--pf-c-notification-badge--m-read--after--BorderColor); }
  .pf-c-notification-badge.pf-m-unread {
    --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-notification-badge--m-unread--after--BackgroundColor);
    color: var(--pf-c-notification-badge--m-unread--Color); }
    .pf-c-notification-badge.pf-m-unread:hover {
      --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-notification-badge--m-unread--hover--after--BackgroundColor); }
  .pf-c-notification-badge.pf-m-attention {
    --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-notification-badge--m-attention--after--BackgroundColor);
    color: var(--pf-c-notification-badge--m-attention--Color); }
    .pf-c-notification-badge.pf-m-attention:hover {
      --pf-c-notification-badge--after--BackgroundColor: var(--pf-c-notification-badge--m-attention--hover--after--BackgroundColor); }

.pf-c-notification-badge__count {
  margin-left: var(--pf-c-notification-badge__count--MarginLeft); }
