.pf-c-action-list {
  --pf-c-action-list--m-icon--spacer: 0;
  --pf-c-action-list--child--spacer-base: var(--pf-global--spacer--md);
  --pf-c-action-list--group--spacer-base: var(--pf-global--spacer--2xl); }

.pf-c-action-list,
.pf-c-action-list__group {
  --pf-c-action-list--child--spacer: var(--pf-c-action-list--child--spacer-base);
  --pf-c-action-list--group--spacer: var(--pf-c-action-list--group--spacer-base);
  display: flex;
  align-items: center; }
  .pf-c-action-list > * + *,
  .pf-c-action-list__group > * + * {
    margin-left: var(--pf-c-action-list--child--spacer); }
  .pf-c-action-list > * + .pf-c-action-list__group,
  .pf-c-action-list .pf-c-action-list__group + *,
  .pf-c-action-list__group > * + .pf-c-action-list__group,
  .pf-c-action-list__group .pf-c-action-list__group + * {
    margin-left: var(--pf-c-action-list--group--spacer); }
  .pf-c-action-list.pf-m-icons,
  .pf-c-action-list__group.pf-m-icons {
    --pf-c-action-list--child--spacer: var(--pf-c-action-list--m-icon--spacer); }
