.pf-c-jump-links {
  --pf-c-jump-links__list--Display: flex;
  --pf-c-jump-links__list--Visibility: visible;
  --pf-c-jump-links__list--PaddingTop: 0;
  --pf-c-jump-links__list--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-jump-links__list--PaddingBottom: 0;
  --pf-c-jump-links__list--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-jump-links--m-vertical__list--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-jump-links--m-vertical__list--PaddingRight: 0;
  --pf-c-jump-links--m-vertical__list--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-jump-links--m-vertical__list--PaddingLeft: 0;
  --pf-c-jump-links__list--FlexDirection: row;
  --pf-c-jump-links--m-vertical__list--FlexDirection: column;
  --pf-c-jump-links__list--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-jump-links__list--before--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-jump-links__list--before--BorderRightWidth: 0;
  --pf-c-jump-links__list--before--BorderBottomWidth: 0;
  --pf-c-jump-links__list--before--BorderLeftWidth: 0;
  --pf-c-jump-links--m-vertical__list--before--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-jump-links--m-vertical__list--before--BorderTopWidth: 0;
  --pf-c-jump-links__list__list--MarginTop: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-jump-links__link--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-jump-links__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-jump-links__link--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-jump-links__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-jump-links__list__list__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-jump-links__list__list__link--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-jump-links__list__list__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-jump-links__link--OutlineOffset: calc(-1 * var(--pf-global--spacer--sm));
  --pf-c-jump-links__link--before--BorderTopWidth: 0;
  --pf-c-jump-links__link--before--BorderRightWidth: 0;
  --pf-c-jump-links__link--before--BorderBottomWidth: 0;
  --pf-c-jump-links__link--before--BorderLeftWidth: 0;
  --pf-c-jump-links__link--before--BorderColor: transparent;
  --pf-c-jump-links__item--m-current__link--before--BorderTopWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-jump-links__item--m-current__link--before--BorderLeftWidth: 0;
  --pf-c-jump-links__item--m-current__link--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-jump-links--m-vertical__item--m-current__link--before--BorderTopWidth: 0;
  --pf-c-jump-links--m-vertical__item--m-current__link--before--BorderLeftWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-jump-links__link-text--Color: var(--pf-global--Color--200);
  --pf-c-jump-links__link--hover__link-text--Color: var(--pf-global--Color--100);
  --pf-c-jump-links__link--focus__link-text--Color: var(--pf-global--Color--100);
  --pf-c-jump-links__item--m-current__link-text--Color: var(--pf-global--Color--100);
  --pf-c-jump-links__label--MarginBottom: var(--pf-global--spacer--md);
  --pf-c-jump-links__toggle--MarginTop: calc(-1 * var(--pf-global--spacer--form-element));
  --pf-c-jump-links__toggle--MarginBottom: calc(-1 * var(--pf-global--spacer--form-element));
  --pf-c-jump-links__toggle--MarginLeft: calc(-1 * var(--pf-global--spacer--md));
  --pf-c-jump-links__toggle--Display: none;
  --pf-c-jump-links__toggle--Visibility: hidden;
  --pf-c-jump-links__label--m-toggle--Display: none;
  --pf-c-jump-links__label--m-toggle--Visibility: hidden;
  --pf-c-jump-links__toggle-icon--Color: currentColor;
  --pf-c-jump-links__toggle-icon--Transition: var(--pf-global--Transition);
  --pf-c-jump-links__toggle-icon--Rotate: 0;
  --pf-c-jump-links--m-expanded__toggle-icon--Color: var(--pf-global--Color--100);
  --pf-c-jump-links--m-expanded__toggle-icon--Rotate: 90deg;
  display: flex; }
  .pf-c-jump-links.pf-m-center {
    justify-content: center; }
    .pf-c-jump-links.pf-m-center .pf-c-jump-links__main {
      align-items: center; }
  .pf-c-jump-links.pf-m-vertical {
    --pf-c-jump-links__list--PaddingTop: var(--pf-c-jump-links--m-vertical__list--PaddingTop);
    --pf-c-jump-links__list--PaddingRight: var(--pf-c-jump-links--m-vertical__list--PaddingRight);
    --pf-c-jump-links__list--PaddingBottom: var(--pf-c-jump-links--m-vertical__list--PaddingBottom);
    --pf-c-jump-links__list--PaddingLeft: var(--pf-c-jump-links--m-vertical__list--PaddingLeft);
    --pf-c-jump-links__list--before--BorderTopWidth: var(--pf-c-jump-links--m-vertical__list--before--BorderTopWidth);
    --pf-c-jump-links__list--before--BorderLeftWidth: var(--pf-c-jump-links--m-vertical__list--before--BorderLeftWidth);
    --pf-c-jump-links__item--m-current__link--before--BorderTopWidth: var(--pf-c-jump-links--m-vertical__item--m-current__link--before--BorderTopWidth);
    --pf-c-jump-links__item--m-current__link--before--BorderLeftWidth: var(--pf-c-jump-links--m-vertical__item--m-current__link--before--BorderLeftWidth);
    --pf-c-jump-links__list--FlexDirection: var(--pf-c-jump-links--m-vertical__list--FlexDirection);
    flex-direction: column; }
  .pf-c-jump-links.pf-m-expandable {
    --pf-c-jump-links__list--Display: none;
    --pf-c-jump-links__list--Visibility: hidden;
    --pf-c-jump-links__toggle--Display: block;
    --pf-c-jump-links__toggle--Visibility: visible;
    --pf-c-jump-links__label--m-toggle--Display: block;
    --pf-c-jump-links__label--m-toggle--Visibility: visible; }
  .pf-c-jump-links.pf-m-non-expandable {
    --pf-c-jump-links__list--Display: flex;
    --pf-c-jump-links__list--Visibility: visible;
    --pf-c-jump-links__toggle--Display: none;
    --pf-c-jump-links__toggle--Visibility: hidden;
    --pf-c-jump-links__label--m-toggle--Display: none;
    --pf-c-jump-links__label--m-toggle--Visibility: hidden; }
  @media (min-width: 576px) {
    .pf-c-jump-links.pf-m-expandable-on-sm {
      --pf-c-jump-links__list--Display: none;
      --pf-c-jump-links__list--Visibility: hidden;
      --pf-c-jump-links__toggle--Display: block;
      --pf-c-jump-links__toggle--Visibility: visible;
      --pf-c-jump-links__label--m-toggle--Display: block;
      --pf-c-jump-links__label--m-toggle--Visibility: visible; }
    .pf-c-jump-links.pf-m-non-expandable-on-sm {
      --pf-c-jump-links__list--Display: flex;
      --pf-c-jump-links__list--Visibility: visible;
      --pf-c-jump-links__toggle--Display: none;
      --pf-c-jump-links__toggle--Visibility: hidden;
      --pf-c-jump-links__label--m-toggle--Display: none;
      --pf-c-jump-links__label--m-toggle--Visibility: hidden; } }
  @media (min-width: 768px) {
    .pf-c-jump-links.pf-m-expandable-on-md {
      --pf-c-jump-links__list--Display: none;
      --pf-c-jump-links__list--Visibility: hidden;
      --pf-c-jump-links__toggle--Display: block;
      --pf-c-jump-links__toggle--Visibility: visible;
      --pf-c-jump-links__label--m-toggle--Display: block;
      --pf-c-jump-links__label--m-toggle--Visibility: visible; }
    .pf-c-jump-links.pf-m-non-expandable-on-md {
      --pf-c-jump-links__list--Display: flex;
      --pf-c-jump-links__list--Visibility: visible;
      --pf-c-jump-links__toggle--Display: none;
      --pf-c-jump-links__toggle--Visibility: hidden;
      --pf-c-jump-links__label--m-toggle--Display: none;
      --pf-c-jump-links__label--m-toggle--Visibility: hidden; } }
  @media (min-width: 992px) {
    .pf-c-jump-links.pf-m-expandable-on-lg {
      --pf-c-jump-links__list--Display: none;
      --pf-c-jump-links__list--Visibility: hidden;
      --pf-c-jump-links__toggle--Display: block;
      --pf-c-jump-links__toggle--Visibility: visible;
      --pf-c-jump-links__label--m-toggle--Display: block;
      --pf-c-jump-links__label--m-toggle--Visibility: visible; }
    .pf-c-jump-links.pf-m-non-expandable-on-lg {
      --pf-c-jump-links__list--Display: flex;
      --pf-c-jump-links__list--Visibility: visible;
      --pf-c-jump-links__toggle--Display: none;
      --pf-c-jump-links__toggle--Visibility: hidden;
      --pf-c-jump-links__label--m-toggle--Display: none;
      --pf-c-jump-links__label--m-toggle--Visibility: hidden; } }
  @media (min-width: 1200px) {
    .pf-c-jump-links.pf-m-expandable-on-xl {
      --pf-c-jump-links__list--Display: none;
      --pf-c-jump-links__list--Visibility: hidden;
      --pf-c-jump-links__toggle--Display: block;
      --pf-c-jump-links__toggle--Visibility: visible;
      --pf-c-jump-links__label--m-toggle--Display: block;
      --pf-c-jump-links__label--m-toggle--Visibility: visible; }
    .pf-c-jump-links.pf-m-non-expandable-on-xl {
      --pf-c-jump-links__list--Display: flex;
      --pf-c-jump-links__list--Visibility: visible;
      --pf-c-jump-links__toggle--Display: none;
      --pf-c-jump-links__toggle--Visibility: hidden;
      --pf-c-jump-links__label--m-toggle--Display: none;
      --pf-c-jump-links__label--m-toggle--Visibility: hidden; } }
  @media (min-width: 1450px) {
    .pf-c-jump-links.pf-m-expandable-on-2xl {
      --pf-c-jump-links__list--Display: none;
      --pf-c-jump-links__list--Visibility: hidden;
      --pf-c-jump-links__toggle--Display: block;
      --pf-c-jump-links__toggle--Visibility: visible;
      --pf-c-jump-links__label--m-toggle--Display: block;
      --pf-c-jump-links__label--m-toggle--Visibility: visible; }
    .pf-c-jump-links.pf-m-non-expandable-on-2xl {
      --pf-c-jump-links__list--Display: flex;
      --pf-c-jump-links__list--Visibility: visible;
      --pf-c-jump-links__toggle--Display: none;
      --pf-c-jump-links__toggle--Visibility: hidden;
      --pf-c-jump-links__label--m-toggle--Display: none;
      --pf-c-jump-links__label--m-toggle--Visibility: hidden; } }
  .pf-c-jump-links.pf-m-expanded {
    --pf-c-jump-links__list--Display: flex;
    --pf-c-jump-links__list--Visibility: visible;
    --pf-c-jump-links__toggle-icon--Rotate: var(--pf-c-jump-links--m-expanded__toggle-icon--Rotate);
    --pf-c-jump-links__toggle-icon--Color: var(--pf-c-jump-links--m-expanded__toggle-icon--Color); }

.pf-c-jump-links__list {
  position: relative;
  display: var(--pf-c-jump-links__list--Display);
  flex-direction: var(--pf-c-jump-links__list--FlexDirection);
  padding-top: var(--pf-c-jump-links__list--PaddingTop);
  padding-right: var(--pf-c-jump-links__list--PaddingRight);
  padding-bottom: var(--pf-c-jump-links__list--PaddingBottom);
  padding-left: var(--pf-c-jump-links__list--PaddingLeft);
  visibility: var(--pf-c-jump-links__list--Visibility); }
  .pf-c-jump-links__list::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: "";
    border: solid var(--pf-c-jump-links__list--before--BorderColor);
    border-width: var(--pf-c-jump-links__list--before--BorderTopWidth) var(--pf-c-jump-links__list--before--BorderRightWidth) var(--pf-c-jump-links__list--before--BorderBottomWidth) var(--pf-c-jump-links__list--before--BorderLeftWidth); }
  .pf-c-jump-links__list .pf-c-jump-links__list {
    --pf-c-jump-links__list--PaddingTop: 0;
    --pf-c-jump-links__list--PaddingBottom: 0;
    --pf-c-jump-links__link--PaddingTop: var(--pf-c-jump-links__list__list__link--PaddingTop);
    --pf-c-jump-links__link--PaddingBottom: var(--pf-c-jump-links__list__list__link--PaddingBottom);
    --pf-c-jump-links__link--PaddingLeft: var(--pf-c-jump-links__list__list__link--PaddingLeft);
    margin-top: var(--pf-c-jump-links__list__list--MarginTop); }

.pf-c-jump-links__link {
  position: relative;
  display: flex;
  flex: 1;
  padding-top: var(--pf-c-jump-links__link--PaddingTop);
  padding-right: var(--pf-c-jump-links__link--PaddingRight);
  padding-bottom: var(--pf-c-jump-links__link--PaddingBottom);
  padding-left: var(--pf-c-jump-links__link--PaddingLeft);
  text-decoration: none;
  outline-offset: var(--pf-c-jump-links__link--OutlineOffset); }
  .pf-c-jump-links__link:hover {
    --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__link--hover__link-text--Color); }
  .pf-c-jump-links__link:focus {
    --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__link--focus__link-text--Color); }
  .pf-c-jump-links__link::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: "";
    border-color: var(--pf-c-jump-links__link--before--BorderColor);
    border-style: solid;
    border-width: var(--pf-c-jump-links__link--before--BorderTopWidth) var(--pf-c-jump-links__link--before--BorderRightWidth) var(--pf-c-jump-links__link--before--BorderBottomWidth) var(--pf-c-jump-links__link--before--BorderLeftWidth); }

.pf-c-jump-links__item {
  --pf-c-jump-links__list--before--BorderColor: transparent; }
  .pf-c-jump-links__item.pf-m-current > .pf-c-jump-links__link {
    --pf-c-jump-links__link--before--BorderTopWidth: var(--pf-c-jump-links__item--m-current__link--before--BorderTopWidth);
    --pf-c-jump-links__link--before--BorderLeftWidth: var(--pf-c-jump-links__item--m-current__link--before--BorderLeftWidth);
    --pf-c-jump-links__link--before--BorderColor: var(--pf-c-jump-links__item--m-current__link--before--BorderColor);
    --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__item--m-current__link-text--Color); }

.pf-c-jump-links__link-text {
  color: var(--pf-c-jump-links__link-text--Color); }

.pf-c-jump-links__header {
  display: flex; }

.pf-c-jump-links__label {
  margin-bottom: var(--pf-c-jump-links__label--MarginBottom); }
  .pf-c-jump-links__label.pf-m-toggle {
    display: var(--pf-c-jump-links__label--m-toggle--Display);
    visibility: var(--pf-c-jump-links__label--m-toggle--Visibility); }

.pf-c-jump-links__main {
  display: flex;
  flex-direction: column; }

.pf-c-jump-links__toggle {
  display: var(--pf-c-jump-links__toggle--Display);
  margin-top: var(--pf-c-jump-links__toggle--MarginTop);
  margin-bottom: var(--pf-c-jump-links__toggle--MarginBottom);
  margin-left: var(--pf-c-jump-links__toggle--MarginLeft);
  visibility: var(--pf-c-jump-links__toggle--Visibility); }

.pf-c-jump-links__toggle-icon {
  display: inline-block;
  color: var(--pf-c-jump-links__toggle-icon--Color);
  transition: var(--pf-c-jump-links__toggle-icon--Transition);
  transform: rotate(var(--pf-c-jump-links__toggle-icon--Rotate)); }
