.pf-c-overflow-menu {
  --pf-c-overflow-menu--spacer--base: var(--pf-global--spacer--md);
  --pf-c-overflow-menu--spacer: var(--pf-global--spacer--sm);
  --pf-c-overflow-menu__group--spacer: var(--pf-c-overflow-menu--spacer--base);
  --pf-c-overflow-menu__item--spacer: var(--pf-c-overflow-menu--spacer--base);
  --pf-c-overflow-menu--c-divider--m-vertical--spacer: var(--pf-c-overflow-menu--spacer--base);
  --pf-c-overflow-menu__group--m-button-group--spacer: var(--pf-c-overflow-menu--spacer--base);
  --pf-c-overflow-menu__group--m-button-group--space-items: var(--pf-global--spacer--sm);
  --pf-c-overflow-menu__group--m-icon-button-group--spacer: var(--pf-c-overflow-menu--spacer--base);
  --pf-c-overflow-menu__group--m-icon-button-group--space-items: 0;
  display: inline-flex;
  align-items: center; }

.pf-c-overflow-menu__content {
  display: flex;
  align-items: center; }

.pf-c-overflow-menu__group {
  --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__group--spacer);
  display: flex;
  align-items: center; }
  .pf-c-overflow-menu__group.pf-m-button-group {
    --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__group--m-button-group--spacer); }
    .pf-c-overflow-menu__group.pf-m-button-group > * {
      --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__group--m-button-group--space-items); }
  .pf-c-overflow-menu__group.pf-m-icon-button-group {
    --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__group--m-icon-button-group--spacer); }
    .pf-c-overflow-menu__group.pf-m-icon-button-group > * {
      --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__group--m-icon-button-group--space-items); }

.pf-c-overflow-menu__item {
  --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu__item--spacer); }

.pf-c-overflow-menu__content,
.pf-c-overflow-menu__control,
.pf-c-overflow-menu__group,
.pf-c-overflow-menu__item {
  margin-right: var(--pf-c-overflow-menu--spacer); }
  .pf-c-overflow-menu__content:last-child,
  .pf-c-overflow-menu__control:last-child,
  .pf-c-overflow-menu__group:last-child,
  .pf-c-overflow-menu__item:last-child {
    --pf-c-overflow-menu--spacer: 0; }

.pf-c-overflow-menu > .pf-c-divider,
.pf-c-overflow-menu__group > .pf-c-divider {
  --pf-c-overflow-menu--spacer: var(--pf-c-overflow-menu--c-divider--m-vertical--spacer); }

.pf-c-overflow-menu > .pf-c-divider.pf-m-vertical,
.pf-c-overflow-menu__group > .pf-c-divider.pf-m-vertical {
  margin-right: var(--pf-c-overflow-menu--spacer); }
  .pf-c-overflow-menu > .pf-c-divider.pf-m-vertical:last-child,
  .pf-c-overflow-menu__group > .pf-c-divider.pf-m-vertical:last-child {
    --pf-c-overflow-menu--spacer: 0; }
