.pf-c-description-list {
  --pf-c-description-list--RowGap: var(--pf-global--gutter--md);
  --pf-c-description-list--ColumnGap: var(--pf-global--spacer--lg);
  --pf-c-description-list--GridTemplateColumns--count: 1;
  --pf-c-description-list--GridTemplateColumns--width: 1fr;
  --pf-c-description-list--GridTemplateColumns: repeat(var(--pf-c-description-list--GridTemplateColumns--count), var(--pf-c-description-list--GridTemplateColumns--width));
  --pf-c-description-list__group--RowGap: var(--pf-global--spacer--sm);
  --pf-c-description-list__group--ColumnGap: var(--pf-global--spacer--md);
  --pf-c-description-list__group--GridTemplateColumns: auto;
  --pf-c-description-list__group--GridColumn: auto;
  --pf-c-description-list__term--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-description-list__term--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-description-list--m-horizontal__term--width: 12ch;
  --pf-c-description-list--m-horizontal__description--width: minmax(10ch, auto);
  --pf-c-description-list--m-horizontal__group--GridTemplateColumns: var(--pf-c-description-list__term--width) var(--pf-c-description-list--m-horizontal__description--width);
  --pf-c-description-list--m-1-col--GridTemplateColumns--count: 1;
  --pf-c-description-list--m-auto-fit--GridTemplateColumns--min: 15.625rem;
  --pf-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min: var(--pf-c-description-list--m-auto-fit--GridTemplateColumns--min);
  display: grid;
  align-items: baseline;
  row-gap: var(--pf-c-description-list--RowGap);
  column-gap: var(--pf-c-description-list--ColumnGap);
  grid-template-columns: var(--pf-c-description-list--GridTemplateColumns); }
  @media screen and (min-width: 768px) {
    .pf-c-description-list {
      --pf-c-description-list--m-2-col--GridTemplateColumns--count: 2;
      --pf-c-description-list--m-3-col--GridTemplateColumns--count: 3; } }
  .pf-c-description-list.pf-m-horizontal {
    --pf-c-description-list__group--GridTemplateColumns: var(--pf-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-c-description-list__term--width: var(--pf-c-description-list--m-horizontal__term--width); }
    @media (min-width: 768px) {
      .pf-c-description-list.pf-m-horizontal {
        --pf-c-description-list__term--width: var(--pf-c-description-list--m-horizontal__term--width-on-md, var(--pf-c-description-list--m-horizontal__term--width)); } }
    @media (min-width: 992px) {
      .pf-c-description-list.pf-m-horizontal {
        --pf-c-description-list__term--width: var(--pf-c-description-list--m-horizontal__term--width-on-lg, var(--pf-c-description-list--m-horizontal__term--width-on-md, var(--pf-c-description-list--m-horizontal__term--width))); } }
    @media (min-width: 1200px) {
      .pf-c-description-list.pf-m-horizontal {
        --pf-c-description-list__term--width: var(--pf-c-description-list--m-horizontal__term--width-on-xl, var(--pf-c-description-list--m-horizontal__term--width-on-lg, var(--pf-c-description-list--m-horizontal__term--width-on-md, var(--pf-c-description-list--m-horizontal__term--width)))); } }
    @media (min-width: 1450px) {
      .pf-c-description-list.pf-m-horizontal {
        --pf-c-description-list__term--width: var(--pf-c-description-list--m-horizontal__term--width-on-2xl, var(--pf-c-description-list--m-horizontal__term--width-on-xl, var(--pf-c-description-list--m-horizontal__term--width-on-lg, var(--pf-c-description-list--m-horizontal__term--width-on-md, var(--pf-c-description-list--m-horizontal__term--width))))); } }
  .pf-c-description-list.pf-m-inline-grid {
    display: inline-grid; }
  .pf-c-description-list.pf-m-auto-column-widths {
    --pf-c-description-list--GridTemplateColumns--width: minmax(8ch, max-content); }
  .pf-c-description-list.pf-m-auto-fit {
    grid-template-columns: repeat(auto-fit, minmax(var(--pf-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min), 1fr));
    --pf-c-description-list--GridTemplateColumns--minmax--min: var(--pf-c-description-list--GridTemplateColumns--min); }
    @media (min-width: 768px) {
      .pf-c-description-list.pf-m-auto-fit {
        --pf-c-description-list--GridTemplateColumns--minmax--min: var(--pf-c-description-list--GridTemplateColumns--min-on-md, var(--pf-c-description-list--GridTemplateColumns--min)); } }
    @media (min-width: 992px) {
      .pf-c-description-list.pf-m-auto-fit {
        --pf-c-description-list--GridTemplateColumns--minmax--min: var(--pf-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-c-description-list--GridTemplateColumns--min-on-md, var(--pf-c-description-list--GridTemplateColumns--min))); } }
    @media (min-width: 1200px) {
      .pf-c-description-list.pf-m-auto-fit {
        --pf-c-description-list--GridTemplateColumns--minmax--min: var(--pf-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-c-description-list--GridTemplateColumns--min-on-md, var(--pf-c-description-list--GridTemplateColumns--min)))); } }
    @media (min-width: 1450px) {
      .pf-c-description-list.pf-m-auto-fit {
        --pf-c-description-list--GridTemplateColumns--minmax--min: var(--pf-c-description-list--GridTemplateColumns--min-on-2xl, var(--pf-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-c-description-list--GridTemplateColumns--min-on-md, var(--pf-c-description-list--GridTemplateColumns--min))))); } }

.pf-c-description-list__group {
  display: grid;
  grid-column: var(--pf-c-description-list__group--GridColumn);
  row-gap: var(--pf-c-description-list__group--RowGap);
  column-gap: var(--pf-c-description-list__group--ColumnGap);
  grid-template-columns: var(--pf-c-description-list__group--GridTemplateColumns);
  align-items: baseline; }

.pf-c-description-list__term,
.pf-c-description-list__description {
  text-align: left; }

.pf-c-description-list__term {
  font-size: var(--pf-c-description-list__term--FontSize);
  font-weight: var(--pf-c-description-list__term--FontWeight); }
  .pf-c-description-list__term .pf-c-description-list__text {
    display: inline; }

.pf-c-description-list.pf-m-1-col {
  --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-1-col--GridTemplateColumns--count); }

.pf-c-description-list.pf-m-2-col {
  --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-2-col--GridTemplateColumns--count); }

.pf-c-description-list.pf-m-3-col {
  --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-3-col--GridTemplateColumns--count); }

@media (min-width: 768px) {
  .pf-c-description-list.pf-m-1-col-on-md {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-1-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-2-col-on-md {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-2-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-3-col-on-md {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-3-col--GridTemplateColumns--count); } }

@media (min-width: 992px) {
  .pf-c-description-list.pf-m-1-col-on-lg {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-1-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-2-col-on-lg {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-2-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-3-col-on-lg {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-3-col--GridTemplateColumns--count); } }

@media (min-width: 1200px) {
  .pf-c-description-list.pf-m-1-col-on-xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-1-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-2-col-on-xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-2-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-3-col-on-xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-3-col--GridTemplateColumns--count); } }

@media (min-width: 1450px) {
  .pf-c-description-list.pf-m-1-col-on-2xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-1-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-2-col-on-2xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-2-col--GridTemplateColumns--count); }
  .pf-c-description-list.pf-m-3-col-on-2xl {
    --pf-c-description-list--GridTemplateColumns--count: var(--pf-c-description-list--m-3-col--GridTemplateColumns--count); } }
